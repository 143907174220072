import React from "react";
import Avatar from "../images/avatar.svg";

const QuizUserList = () => {
	return (
		<div className="w-full flex items-center gap-5 justify-between border-t border-[#eee] py-3 font-bold text-base md:text-xl">
			<div className="flex w-full items-center gap-2 md:gap-4">
				<img
					src={Avatar}
					alt="Avatar"
					className="md:w-[60px] md:h-[60px] w-12 h-12"
				/>
				<p>Clinton</p>
			</div>
			<div className="grid grid-cols-2 w-full sm:w-1/2 justify-end text-right items-center">
				<p>1h 07m</p>
				<p>5.9k</p>
			</div>
		</div>
	);
};

export default QuizUserList;
