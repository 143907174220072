import QuizImg1 from "../images/quiz/Image.png";
import QuizImg2 from "../images/quiz/Image-1.png";
import QuizImg3 from "../images/quiz/Image-2.png";

export const QUIZ = [
	{
		img: QuizImg1,
		title: "Back to School Quiz Ga...",
		totalQuestions: 10,
		createdAt: "Today",
		totalPlays: 20,
		visibility: "Public",
	},
	{
		img: QuizImg2,
		title: "Have Fun Together at S...",
		totalQuestions: 10,
		createdAt: "Today",
		totalPlays: 20,
		visibility: "Public",
	},
	{
		img: QuizImg3,
		title: "Great Ideas Come from...",
		totalQuestions: 10,
		createdAt: "Today",
		totalPlays: 846,
		visibility: "Public",
	},
];
