import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Logo from "../images/logo.svg";
import Avatar from "../images/avatar.svg";
import Swap from "../images/Swap.svg";
import Settings from "../images/settings.svg";
import { QUIZ } from "../constants";
import QuizCard from "../components/QuizCard";
import Layout from "../components/Layout";

const Profile = () => {
	const [quizzes, setQuizzes] = useState([]);
	const navigate = useNavigate();

	useEffect(() => {
		const fetchQuizzes = async () => {
			try {
				const response = await axios.request({
					method: "post",
					maxBodyLength: Infinity,
					url: "http://localhost:3005/aq_quiz/list",
					headers: {
						token:
							"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoxMDAwMDAwLCJlbWFpbCI6InJ1cGFta2luZzEyOTdAZ21haWwuY29tIiwiaWF0IjoxNzAwNzM2MDkzfQ.a5fXF13GaBFM4RFntvB_7OzQz_DY-cbAG4eCMwuAYQ0",
						"Content-Type": "application/json",
					},
					data: JSON.stringify({
						order_by: "ASC",
						// limit: 3,
						// offset: 2,
					}),
				});
				console.log("response.data", response.data.quizzes);
				setQuizzes(response.data.quizzes);
			} catch (error) {
				console.error("Error fetching quizzes:", error);
			}
		};

		fetchQuizzes();
	}, []);

	return (
		<Layout>
			<div className="container-spacing relative flex flex-col items-start w-full h-full bg-white gap-5">
				<div className="flex sticky top-0 bg-theme-white py-4 z-20 w-full items-center justify-between gap-5">
					<img src={Logo} alt="logo" />
					<div className="flex items-center gap-5">
						<img src={Settings} alt="search" />
					</div>
				</div>
				<div className="w-full flex flex-col gap-3">
					<div className="w-full flex items-center gap-6 justify-between">
						<div className="flex items-center gap-5">
							<div className="">
								<img src={Avatar} alt="Avatar" />
							</div>
							<div className="flex flex-col items-start gap-1">
								<p className="text-xl font-bold">Andrew Ainsley</p>
								<p className="text-sm text-theme-gray">@andrew_ainsley</p>
							</div>
						</div>
						<button className="text-sm md:text-base px-4 py-2 font-semibold rounded-full bg-theme-primary text-theme-white">
							Edit Profile
						</button>
					</div>
					<div className="w-full py-3 border-y border-[#eeeeee] flex items-center">
						<div className="w-full flex flex-col items-center gap-1">
							<p className="text-xl font-bold">45</p>
							<p>My Quiz</p>
						</div>
						<div className="w-full flex flex-col items-center gap-1 border-x border-[#eeeeee]">
							<p className="text-xl font-bold">0</p>
							<p>Collections</p>
						</div>
						<div className="w-full flex flex-col items-center gap-1">
							<p className="text-xl font-bold">16.8K</p>
							<p>Plays</p>
						</div>
					</div>
					<div className="my-5 flex items-center gap-2 md:gap-3">
						<button className="w-full px-4 py-2 bg-theme-primary rounded-full text-theme-white font-semibold md:text-base text-sm border-2 border-theme-primary">
							Quiz Played
						</button>
						<button className="w-full px-4 py-2 bg-theme-white rounded-full text-theme-primary font-semibold border-2 border-theme-primary md:text-base text-sm">
							Collections
						</button>
						<button className="w-full px-4 py-2 bg-theme-white rounded-full text-theme-primary font-semibold border-2 border-theme-primary md:text-base text-sm">
							About
						</button>
					</div>
				</div>
				<div className="w-full flex items-center gap-5 justify-between">
					<p className="text-xl font-bold">Quiz Played (4)</p>
					<div className="flex items-center gap-4">
						<p className="text-lg md:text-xl font-bold text-theme-primary">
							Newest
						</p>
						<img src={Swap} alt="Swap" />
					</div>
				</div>
				<div className="flex w-full flex-col items-start gap-5">
					{QUIZ.map((data, i) => {
						return <QuizCard key={i} data={data} />;
					})}
				</div>
			</div>
		</Layout>
	);
};

export default Profile;
