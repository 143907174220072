import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyCqtk7AB1LZZO8xVFFuSc_8Szt6NgiKP3k",
  authDomain: "advance-quiz-1.firebaseapp.com",
  databaseURL: "https://advance-quiz-1-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "advance-quiz-1",
  storageBucket: "advance-quiz-1.appspot.com",
  messagingSenderId: "837410871891",
  appId: "1:837410871891:web:7cf10092945c9896fb84a6",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const analytics = getAnalytics(app);

export { auth };