import React from "react";
import Navbar from "./Navbar";

const Layout = ({ children, noMenu, isBgGradient, noBottomSpacing }) => {
	return (
		<>
			<div className="flex items-center justify-center bg-gray-100">
				<div
					className={`main-container w-full h-full  gap-5 min-h-screen  ${
						noBottomSpacing ? "" : "pb-40"
					} ${
						isBgGradient
							? "bg-gradient-to-l from-green-500 to-green-600"
							: "bg-white"
					}`}
				>
					{isBgGradient ? (
						<div className="bg-main-vector w-full bg-center bg-no-repeat min-h-screen">
							{children}
						</div>
					) : (
						<>{children}</>
					)}
				</div>
				{!noMenu && <Navbar />}
			</div>
		</>
	);
};

export default Layout;
