import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Logo from "../images/logo.svg";
import HomeAvatars from "../images/home-avatars.svg";
import Notification from "../images/Notification.svg";
import Swap from "../images/Swap.svg";
import Search from "../images/Search.svg";
import { QUIZ } from "../constants";
import QuizCard from "../components/QuizCard";
import Layout from "../components/Layout";

const HomePage = () => {
	const [quizzes, setQuizzes] = useState([]);
	const navigate = useNavigate();

	useEffect(() => {
		const fetchQuizzes = async () => {
			try {
				const response = await axios.request({
					method: "post",
					maxBodyLength: Infinity,
					url: "http://localhost:3005/aq_quiz/list",
					headers: {
						token:
							"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoxMDAwMDAwLCJlbWFpbCI6InJ1cGFta2luZzEyOTdAZ21haWwuY29tIiwiaWF0IjoxNzAwNzM2MDkzfQ.a5fXF13GaBFM4RFntvB_7OzQz_DY-cbAG4eCMwuAYQ0",
						"Content-Type": "application/json",
					},
					data: JSON.stringify({
						order_by: "ASC",
						// limit: 3,
						// offset: 2,
					}),
				});
				console.log("response.data", response.data.quizzes);
				setQuizzes(response.data.quizzes);
			} catch (error) {
				console.error("Error fetching quizzes:", error);
			}
		};

		fetchQuizzes();
	}, []);

	return (
		<Layout>
			<div className="relative container-spacing flex flex-col items-start w-full h-full bg-white gap-5">
				<div className="flex sticky top-0 bg-theme-white py-4 z-20 w-full items-center justify-between gap-5">
					<img src={Logo} alt="logo" />
					<div className="flex items-center gap-5">
						<img src={Search} alt="search" />
						<img src={Notification} alt="notification" />
					</div>
				</div>
				<div className="w-full flex flex-col items-start gap-4 p-7 rounded-3xl bg-home-vector bg-no-repeat bg-cover bg-center relative z-10">
					<img
						src={HomeAvatars}
						alt="HomeAvatars"
						className="absolute right-5 top-1/2 -translate-y-1/2 w-[150px] md:w-auto -z-10"
					/>
					<p className="text-xl md:text-2xl font-bold text-theme-white leading-[30px] md:leading-[40px]">
						Play quiz together with
						<br /> your friends now!
					</p>
					<button className="py-1.5 md:py-2 px-4 bg-white rounded-full text-theme-primary md:text-base text-sm font-semibold">
						Find Friends
					</button>
				</div>
				<div className="w-full flex items-center gap-5 justify-between">
					<p className="text-xl font-bold">My Quiz (25)</p>
					<div className="flex items-center gap-4">
						<p className="text-lg md:text-xl font-bold text-theme-primary">
							Newest
						</p>
						<img src={Swap} alt="Swap" />
					</div>
				</div>
				<div className="flex w-full flex-col items-start gap-5">
					{QUIZ.map((data, i) => {
						return <QuizCard key={i} data={data} />;
					})}
				</div>
			</div>
		</Layout>
	);
};

export default HomePage;
