import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LoginPage from "./pages/Login";
import HomePage from "./pages/Home";
import CreatePage from "./pages/Create";
import Profile from "./pages/Profile";
import Submits from "./pages/Submits";
import JoinQuiz from "./pages/JoinQuiz";
import QuizPlay from "./pages/QuizPlay";

const QuizPage = () => {
	// State to store quiz data
	const [quizData, setQuizData] = useState(null);

	// State to store user input
	const [userInput, setUserInput] = useState({});

	// Fetch quiz data from the API
	const fetchQuizData = async () => {
		try {
			const response = await fetch("http://localhost:3005/aq_quiz/start", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					quiz_id: 7,
				}),
			});

			if (!response.ok) {
				throw new Error("Failed to fetch quiz data");
			}

			const data = await response.json();
			setQuizData(data);
		} catch (error) {
			console.error(error);
		}
	};

	// Update user input in state
	const handleInputChange = (fieldTitle, value) => {
		setUserInput((prevInput) => ({
			...prevInput,
			[fieldTitle]: value,
		}));
	};

	// Log user input to the console
	const logUserInput = () => {
		console.log(userInput);
	};

	// Fetch quiz data on component mount
	useEffect(() => {
		fetchQuizData();
	}, []);

	// Render the component
	return (
		<div className="bg-gray-100 p-8">
			{quizData && (
				<div className="max-w-2xl mx-auto bg-white p-6 rounded-md shadow-md">
					{/* Quiz Information */}
					<h1 className="text-2xl font-semibold mb-4">{quizData.title}</h1>
					<p className="mb-2">Total Questions: {quizData.total_question}</p>
					<p className="mb-2">Total Score: {quizData.total_score}</p>
					<p className="mb-4">Total Time: {quizData.total_time} seconds</p>

					{/* Quiz Fields */}
					<div className="mt-4">
						<form>
							{quizData.fields.map((field) => (
								<div key={field.title} className="mb-4">
									<label
										htmlFor={`input-${field.title.replace(/\s+/g, "-")}`}
										className="block text-sm font-medium text-gray-700"
									>
										{field.title}
									</label>
									<input
										type={field.type === "full-name" ? "text" : "email"}
										id={`input-${field.title.replace(/\s+/g, "-")}`}
										name={`input-${field.title.replace(/\s+/g, "-")}`}
										className="mt-1 p-2 w-full md:w-2/3 lg:w-1/2 border rounded-md"
										onChange={(e) =>
											handleInputChange(field.title, e.target.value)
										}
									/>
								</div>
							))}
						</form>
					</div>

					{/* Start Quiz Button */}
					<button
						type="button"
						onClick={logUserInput}
						className="bg-blue-500 text-white px-4 py-2 rounded-md mt-4 w-full md:w-auto"
					>
						Start Quiz
					</button>
				</div>
			)}
		</div>
	);
};

function App() {
	return (
		<Router>
			<div className="App">
				<Routes>
					{/* <Route path="/quiz-play" element={<QuizPage />} /> */}
					<Route path="/login" element={<LoginPage />} />
					<Route path="/home" element={<HomePage />} />
					<Route path="/create" element={<CreatePage />} />
					<Route path="/profile" element={<Profile />} />
					<Route path="/submits" element={<Submits />} />
					<Route path="/join-quiz" element={<JoinQuiz />} />
					<Route path="/quiz-play" element={<QuizPlay />} />
				</Routes>
			</div>
		</Router>
	);
}
export default App;
