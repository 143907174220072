import React from "react";
import HomeIcon from "../images/nav/Home.svg";
import QuizIcon from "../images/nav/Category.svg";
import LogoSM from "../images/nav/logo-sm.svg";
import PlusIcon from "../images/nav/Plus.svg";
import ProfileIcon from "../images/nav/Profile.svg";
const Navbar = () => {
	return (
		<div className="fixed bottom-0 w-full bg-gray-100">
			<div className="main-container w-full bg-white py-5 flex items-center gap-5 justify-between border-t border-gray-50">
				<div className="flex w-full flex-col items-center gap-0.5">
					<img src={HomeIcon} alt="Home" />
					<p className="text-sm font-bold">Home</p>
				</div>
				<div className="flex w-full flex-col items-center gap-0.5">
					<img src={QuizIcon} alt="Quizzes" />
					<p className="text-sm font-bold">Quizzes</p>
				</div>
				<div className="flex w-full flex-col items-center gap-0.5">
					<img src={LogoSM} alt="Join" />
					<p className="text-sm font-bold">Join</p>
				</div>
				<div className="flex w-full flex-col items-center gap-0.5">
					<img src={PlusIcon} alt="Create" />
					<p className="text-sm font-bold">Create</p>
				</div>
				<div className="flex w-full flex-col items-center gap-0.5">
					<img src={ProfileIcon} alt="Profile" />
					<p className="text-sm font-bold">Profile</p>
				</div>
			</div>
		</div>
	);
};

export default Navbar;
