// CreatePage.js
import React, { useState } from 'react';

const CreatePage = () => {
    const [quizData, setQuizData] = useState({
        title: '',
        quizType: 'mcq',
        questions: [],
    });

    const addQuestion = () => {
        setQuizData((prevData) => ({
            ...prevData,
            questions: [...prevData.questions, { question: '', options: ['', '', '', ''], correctOption: 0 }],
        }));
    };

    const handleQuestionChange = (index, field, value) => {
        setQuizData((prevData) => {
            const updatedQuestions = [...prevData.questions];
            updatedQuestions[index][field] = value;
            return { ...prevData, questions: updatedQuestions };
        });
    };

    const handleOptionChange = (questionIndex, optionIndex, value) => {
        handleQuestionChange(questionIndex, 'options', (prevOptions) => {
            const updatedOptions = [...prevOptions];
            updatedOptions[optionIndex] = value;
            return updatedOptions;
        });
    };

    const handleCorrectOptionChange = (questionIndex, value) => {
        handleQuestionChange(questionIndex, 'correctOption', value);
    };

    const createQuiz = async () => {
        // Implement the API call here using fetch or your preferred method
        try {
            const response = await fetch('http://localhost:3005/aq_quiz/create', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(quizData),
            });

            if (response.ok) {
                const data = await response.json();
                console.log('Quiz created successfully. Quiz ID:', data.quiz_id);
                // Optionally, you can redirect the user or perform other actions after quiz creation.
            } else {
                console.error('Failed to create quiz. HTTP Status:', response.status);
            }
        } catch (error) {
            console.error('Error creating quiz:', error);
        }
    };

    return (
        <div className="max-w-md mx-auto p-4">
            <h1 className="text-2xl font-bold mb-4">Create Quiz</h1>

            {quizData.questions.map((question, index) => (
                <div key={index} className="mb-4">
                    <label className="block text-sm font-medium mb-2">Question {index + 1}</label>
                    <input
                        type="text"
                        className="w-full px-3 py-2 border rounded"
                        placeholder="Enter the question"
                        value={question.question}
                        onChange={(e) => handleQuestionChange(index, 'question', e.target.value)}
                    />

                    <div className="mt-2">
                        {question.options.map((option, optionIndex) => (
                            <input
                                key={optionIndex}
                                type="text"
                                className="w-full px-3 py-2 border rounded mt-2"
                                placeholder={`Option ${optionIndex + 1}`}
                                value={option}
                                onChange={(e) => handleOptionChange(index, optionIndex, e.target.value)}
                            />
                        ))}
                    </div>

                    <div className="mt-2">
                        <label className="block text-sm font-medium">Correct Option</label>
                        <select
                            className="w-full px-3 py-2 border rounded"
                            value={question.correctOption}
                            onChange={(e) => handleCorrectOptionChange(index, e.target.value)}
                        >
                            {question.options.map((_, optionIndex) => (
                                <option key={optionIndex} value={optionIndex}>
                                    Option {optionIndex + 1}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
            ))}

            <button className="bg-blue-500 text-white px-4 py-2 rounded" onClick={addQuestion}>
                Add a New Question
            </button>

            <button className="bg-green-500 text-white px-4 py-2 rounded mt-4" onClick={createQuiz}>
                Create Quiz and Save
            </button>
        </div>
    );
};

export default CreatePage;
