/**

 */
async function api(route, body, { token = true, response_ = {} } = {}) {
    if (response_) return response_;
    const response = await fetch('https://serverless.rest' + route, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body)
    });
    return { status: response.status, body: response.body };
}

module.exports = { api };
