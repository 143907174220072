import React from "react";
import Public from "../images/Public.svg";
import Profile from "../images/Profile.svg";
const QuizCard = ({ data }) => {
	return (
		<div className="w-full bg-[#FAFAFA] rounded-xl flex items-center border border-[#EEEEEE] overflow-hidden">
			<div>
				<img
					src={data?.img}
					alt={data?.title}
					className="min-w-[200] min-h-[137px] object-cover"
				/>
			</div>
			<div className="w-full bg-[#FAFAFA] p-4 h-full flex flex-col items-start justify-center gap-3">
				<p className="text-lg font-bold line-clamp-1">{data?.title}</p>
				<div className="flex items-center gap-2 text-sm text-[#616161]">
					<p>{data?.createdAt}</p>
					<p>•</p>
					<p>{data?.totalPlays} plays</p>
				</div>
				<div className="flex items-center gap-2">
					{data?.visibility === "Public" ? (
						<img src={Public} alt="public" className="w-5 h-5" />
					) : (
						<img src={Profile} alt="profile" className="w-5 h-5" />
					)}
					<p className="text-base text-[#424242]">{data?.visibility}</p>
				</div>
			</div>
		</div>
	);
};

export default QuizCard;
