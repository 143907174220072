import React from "react";

const QuizOption = ({ txt }) => {
	return (
		<div className="w-full px-5 py-4 bg-blue-500 border-b-4 md:border-b-8 cursor-pointer hover:scale-95 transition-all border-blue-700  text-lg md:text-xl text-theme-white rounded-xl">
			{txt}
		</div>
	);
};

export default QuizOption;
