import React from "react";
import Layout from "../components/Layout";
import QuizJoinCard from "../components/QuizJoinCard";
import GoogleLogo from "../images/google.svg";
import Input from "../components/Input";
import SocialBtn from "../components/SocialBtn";

const JoinQuiz = () => {
	return (
		<Layout noMenu isBgGradient noBottomSpacing>
			<div className="w-full h-full flex flex-col gap-6">
				<div className="w-full text-center py-4 text-theme-white text-2xl font-bold">
					<p>Quiz App</p>
				</div>
				<div className="flex container-spacing items-center justify-center w-full">
					<div className="w-full sm:w-[70%]">
						<QuizJoinCard />
					</div>
				</div>
				<div className="bg-theme-white container-spacing py-8 rounded-t-3xl flex flex-col items-start gap-6">
					<p className="font-bold text-3xl text-theme-dark">
						Login and Join Quiz
					</p>
					<Input label="Email" placeholder="john@yourdomain.com" type="email" />
					<button className="w-full bg-green-500 flex items-center gap-3 justify-center py-[18px] px-[32px] border border-green-600 text-white rounded-full border-b-4">
						<p className="text-base font-semibold">SIGN IN / SIGN UP</p>
					</button>
					<div className="w-full flex items-center justify-between gap-3">
						<div className="w-full h-[1px] bg-[#EEEEEE]" />
						<p className="font-semibold text-[#616161]">or</p>
						<div className="w-full h-[1px] bg-[#EEEEEE]" />
					</div>
					<SocialBtn btnTxt="Continue with Google" icon={GoogleLogo} />
				</div>
			</div>
		</Layout>
	);
};

export default JoinQuiz;
