import React from "react";
import Layout from "../components/Layout";
import ThreeDots from "../images/three-dots.svg";
import ImagePlaceholder from "../images/quiz-play.svg";
import Slider from "../components/Slider";
import QuizOption from "../components/QuizOption";
const QuizPlay = () => {
	return (
		<div className="w-full">
			<Layout noMenu>
				<div className="container-spacing relative flex flex-col items-start w-full h-full bg-white gap-5">
					<div className="flex sticky top-0 bg-theme-white py-4 z-20 w-full items-center justify-between gap-5">
						<p className="text-base lg:text-lg font-bold">3/10</p>
						<p className="text-base lg:text-lg font-bold">MCQ</p>
						<div className="flex items-center gap-5">
							<img src={ThreeDots} alt="ThreeDots" />
						</div>
					</div>
					<Slider width={30} />
					<img
						src={ImagePlaceholder}
						alt="quiz play"
						className="w-full aspect-video"
					/>
					<p className="text-2xl md:text-3xl self-center text-center font-bold">
						Order the following simple words!
					</p>
					<div className="w-full bg-theme-gray h-[1px] bg-opacity-10 my-3 " />
					<div className="w-full flex flex-col items-start gap-4">
						<QuizOption txt="Option 1" />
						<QuizOption txt="Option 2" />
						<QuizOption txt="Option 3" />
						<QuizOption txt="Option 4" />
					</div>
				</div>
			</Layout>
			<div className="fixed bottom-0 w-full bg-gray-100">
				<div className="main-container px-5 w-full bg-white py-5 flex items-center gap-5 justify-between border-t border-gray-50">
					<button className="w-full bg-green-500 flex items-center gap-3 justify-center py-[18px] px-[32px] border border-green-600 text-white rounded-full border-b-4">
						<p className="text-xl font-semibold">Submit Answer</p>
					</button>
				</div>
			</div>
		</div>
	);
};

export default QuizPlay;
