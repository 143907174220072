import React from "react";

const Slider = ({ width }) => {
	return (
		<div className="w-full h-4 rounded-full bg-zinc-100">
			<div
				style={{ width: `${width}%` }}
				className="h-full rounded-full text-xs text-theme-white bg-rose-500 flex items-center font-bold justify-center"
			>{`${width}`}</div>
		</div>
	);
};

export default Slider;
