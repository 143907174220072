import React from "react";

const Input = ({ label, placeholder, type }) => {
	return (
		<div className="w-full font-bold flex flex-col items-start gap-4">
			<p>{label}</p>
			<input
				placeholder={placeholder}
				type={type}
				className="w-full pb-2 text-xl outline-none bg-transparent border-b border-violet-600"
			/>
		</div>
	);
};

export default Input;
