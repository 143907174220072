import React from "react";
import placeholder from "../images/placeholder-1.png";

const QuizJoinCard = () => {
	return (
		<div className="w-full rounded-[28px] bg-theme-white p-4 md:p-5 flex flex-col items-center gap-2 sm:gap-3 md:gap-4 text-center">
			<div className="w-full aspect-video overflow-hidden rounded-[20px]">
				<img
					src={placeholder}
					alt="placeholder"
					className="w-full h-full object-cover object-center"
				/>
			</div>
			<p className="font-bold text-theme-dark text-lg sm:text-xl md:text-2xl">
				Back to School Quiz Game
			</p>
			<div className="w-full h-[1px] bg-[#eee]" />
			<p className="text-base sm:text-lg md:text-xl font-semibold">
				12 players have played
			</p>
		</div>
	);
};

export default QuizJoinCard;
