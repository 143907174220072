import React, { useEffect } from "react";
import { signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { auth } from "../functions/firebase";
import { api } from "../functions/api.js";
import { BrowserRouter as Router, useNavigate } from "react-router-dom";
import { GoArrowLeft } from "react-icons/go";
import Input from "../components/Input.js";
import GoogleLogo from "../images/google.svg";
import AppleLogo from "../images/apple.svg";
import FacebookLogo from "../images/facebook.svg";
import SocialBtn from "../components/SocialBtn.js";

const LoginPage = () => {
	const navigate = useNavigate();

	useEffect(() => {
		const apiToken = localStorage.getItem("api_token");
		console.log(apiToken);
		if (apiToken) navigate("/home");
	}, [navigate]);

	const signInWithGoogle = async () => {
		const provider = new GoogleAuthProvider();
		try {
			const result = await signInWithPopup(auth, provider);
			if (result.user) {
				const idToken = await result.user.getIdToken();
				localStorage.setItem("firebase_idToken", idToken); // console.log("User's ID Token:", idToken);
				const response = await api(
					"/user/login",
					{ firebase_token: idToken },
					{ token: false }
				);
				console.log(response.status);

				if (response.status === 200) {
					const body = await response.body;
					localStorage.setItem("api_token", body.token); // console.log(body);
					navigate("/home");
				} else console.error("Failed to log in via API", await response.text());
			}
		} catch (error) {
			console.error(error);
		}
	};

	return (
		<div className="flex items-center justify-center bg-gray-100">
			<div className="relative main-container min-h-screen flex flex-col items-start pt-4 w-full h-full bg-white container-spacing gap-5 pb-40">
				<div className="w-full flex py-3 items-center justify-between gap-5">
					<div className="text-2xl">
						<GoArrowLeft />
					</div>
				</div>
				<h2 className="font-bold text-3xl">Hello there 👋</h2>
				<Input label="Email" placeholder="john@yourdomain.com" type="email" />
				<div className="w-full flex items-center justify-between gap-3">
					<div className="w-full h-[1px] bg-[#EEEEEE]" />
					<p className="font-semibold text-[#616161]">or</p>
					<div className="w-full h-[1px] bg-[#EEEEEE]" />
				</div>
				<SocialBtn btnTxt="Continue with Google" icon={GoogleLogo} />
				<SocialBtn btnTxt="Continue with Apple" icon={AppleLogo} />
				<SocialBtn btnTxt="Continue with Facebook" icon={FacebookLogo} />
				{/* <div className="p-8 rounded shadow-md max-w-md w-full">
					<button
						className="bg-blue-500 text-white px-4 py-2 rounded-md w-full"
						onClick={async () => {
							await signInWithGoogle();
						}}
					>
						Sign in with Google
					</button>
				</div> */}
				<div className="absolute bottom-4 left-0 px-6 w-full py-6">
					<button className="w-full bg-green-500 flex items-center gap-3 justify-center py-[18px] px-[32px] border border-green-600 text-white rounded-full border-b-4">
						<p className="text-base font-semibold">SIGN IN / SIGN UP</p>
					</button>
				</div>
			</div>
		</div>
	);
};

export default LoginPage;
