import React from "react";
import Layout from "../components/Layout";
import Swap from "../images/Swap.svg";
import Logo from "../images/logo.svg";
import Search from "../images/Search.svg";
import ThreeDots from "../images/three-dots.svg";
import QuizUserList from "../components/QuizUserList";

const Submits = () => {
	return (
		<Layout>
			<div className="container-spacing relative flex flex-col items-start w-full h-full bg-white gap-5">
				<div className="flex sticky top-0 bg-theme-white py-4 z-20 w-full items-center justify-between gap-5">
					<img src={Logo} alt="logo" />
					<div className="flex items-center gap-5">
						<img src={Search} alt="search" />
						<img src={ThreeDots} alt="ThreeDots" />
					</div>
				</div>
				<div className="w-full flex items-center gap-4 justify-between">
					<button className="w-full bg-[#BAFFDA] text-green-600 flex items-center gap-3 justify-center p-[18px] border-green-600 rounded-full border-b-4">
						Share
					</button>
					<button className="w-full bg-green-500 flex items-center gap-3 justify-center p-[18px] border-green-600 text-white rounded-full border-b-4">
						Edit Quiz
					</button>
				</div>
				<div className="w-full flex items-center gap-5 justify-between">
					<p className="text-xl font-bold">Quiz Submits</p>
					<div className="flex items-center gap-4">
						<p className="text-lg md:text-xl font-bold text-theme-primary">
							Newest
						</p>
						<img src={Swap} alt="Swap" />
					</div>
				</div>
				<div className="w-full bg-[#fafafa] px-6 py-3 rounded-t-2xl flex flex-col items-start">
					<div className="w-full flex items-center gap-5 justify-between py-2 font-bold text-xl">
						<div className="w-full">Name</div>
						<div className="grid grid-cols-2 w-full sm:w-1/2 justify-end text-right items-center">
							<p>Time</p>
							<p>Pts</p>
						</div>
					</div>
					<QuizUserList />
					<QuizUserList />
					<QuizUserList />
					<QuizUserList />
					<QuizUserList />
				</div>
			</div>
		</Layout>
	);
};

export default Submits;
