import React from "react";

const SocialBtn = ({ icon, btnTxt }) => {
	return (
		<button className="w-full flex items-center gap-3 justify-center py-[18px] px-[32px] border border-[#EEEEEE] rounded-2xl border-b-4">
			<div>
				<img src={icon} className="w-6 h-6" />
			</div>
			<p className="text-base font-semibold">{btnTxt}</p>
		</button>
	);
};

export default SocialBtn;
